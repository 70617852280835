import { configureStore } from '@reduxjs/toolkit'
import dashboardSlice from './context/dashboardSlice'

export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    // add other slice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
