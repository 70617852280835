import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

export interface DashboardState {
  documentId: number
  documentName: string
}

const initialState: DashboardState = {
  documentId: 0,
  documentName: 'nimbusDocument',
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard: (state) => {
      state.documentId = initialState.documentId
      state.documentName = initialState.documentName
    },
    // actions will add in here
  },
})

// actions will export in here
export const { resetDashboard } = dashboardSlice.actions

export const selectDocumentId = (state: RootState) => state.dashboard.documentId
export const selectDocumentName = (state: RootState) => state.dashboard.documentName

export default dashboardSlice.reducer
